.container {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

main {
    padding: 5rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
}