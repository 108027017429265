#editor-container {
    margin: 20pt auto;
    width: 600pt;
}

.stateMessage {
    position: absolute;
    top: 0;
    margin: 10pt;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
}

#error {
    color: red;
}

.buttons-container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20pt;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tox-notification {
    display: none !important
}

.stateMessage {
    margin: 0;
    text-align: center;
}